<template>
    <span class="dropdown d-inline ms-1">
        <button class="btn btn-primary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <i class="bi bi-funnel-fill"></i> {{ $t('Show') }}
        </button>
            
        <ul class="dropdown-menu">
                <li v-for="item in filterObject">
                    <label class="dropdown-item ">
                        <input type="checkbox" class="form-check-input me-1" v-model="item.bool" @change="handleChange">
                        {{ $t( item.type ) }}
                    </label>
                </li>
        </ul>
    </span>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import $t from "o365.modules.translate.ts";

const props = defineProps({
    filterByNodeTypes: {
        type: Array,
        default: []
    }
})

const filterObject = ref<Array<{ type: string; bool: boolean }>>([]);

onMounted(()=>{
    props.filterByNodeTypes.forEach((x:string)=>{
        filterObject.value.push({
            type: x,
            bool: true
        });
    })
})



const emit = defineEmits([
    "fieldChanged"
]);

function handleChange(){
    emit('fieldChanged', filterObject.value)
}

</script>